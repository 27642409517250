import { mapActions, mapGetters, mapState } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'AddAdminForm',
  Components: {
    Modal
  },
  props: {
    adminData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      errors: [],
      isEdit: false,
      editPassword: false,
      name: '',
      username: '',
      password: '',
      confirmPassword: '',
      roles: []
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapGetters('user', ['token']),
    ...mapState('admin', ['availableRoles']),
    requestData () {
      return {
        id: this.adminData.id,
        token: this.token,
        editPassword: this.editPassword,
        admin: {
          name: this.name,
          username: this.username,
          password: this.password,
          roles: this.roles
        }
      }
    },
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('admin', ['addAdminAction', 'updateAdminAction', 'getAdminsAction', 'rolesAdminAction']),
    close () {
      this.$emit('close')
    },
    initPage () {
      this.rolesAdminAction()
      if (Object.keys(this.adminData).length > 0) {
        const { name, username, roles } = this.adminData
        this.isEdit = true
        this.name = name
        this.username = username
        this.roles = roles
      }
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length === 0) {
        if (this.isEdit) {
          this.updateAdminAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        } else {
          this.addAdminAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        }
      }
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.getAdminsAction({
        success: () => {}
      })
      this.close()
    },
    validateForm () {
      this.errors = []
      if (this.name === '') this.errors.push(emptyField('Name'))
      if (this.username === '') this.errors.push(emptyField('Username'))
      if (this.roles === '') this.errors.push(emptyField('Roles'))
      if (this.editPassword && this.password === '') this.errors.push(emptyField('Password'))
      if (this.password !== this.confirmPassword) this.errors.push(emptyField('Confirm password tidak match'))
    },
    pickRole (role) {
      if (this.hasBeenSelectedRole(role)) {
        this.roles = this.roles.filter(r => r !== role)
      } else {
        this.roles.push(role)
      }
    },
    hasBeenSelectedRole (role) {
      return this.roles.includes(role)
    }
  }
}
